// Copywrite Justin Roseman 2021

import { mapActions, mapGetters } from 'vuex'
import { BASE_URL } from '../../apis/apiUrls'
import { v4 as uuidv4 } from 'uuid'
import { vaultAPI } from '@/apis/apis'
import geo from '@/constants/geo-info'
import { setTimeout } from 'core-js'

export default {
  name: 'create-post',
  props: ['createPostModalOpen', 'vault'],
  data() {
    return {
      form: {
        fileType: 'image',
        file: null,
        description: null,
      },
    }
  },
  async created() {},
  methods: {
    ...mapActions(['fetchPosts', 'uploadPost']),

    async onFileSelect() {
      console.log('file selected')
      this.form.file = this.$refs.fileInput.files[0]
      console.log('this is file info ', this.form.file)
    },
    async submitVaultPost() {
      console.log('entered onFileSubmit', this.form.file)
      if (!this.form.file) {
        alert('must select file before uploading')
        return
      }
      const formData = new FormData()
      formData.append('file', this.form.file)

      const status = this.vault.user_account_id === this.getLoggedInUser.id ? 'approved' : 'review'

      const response = await this.uploadPost({
        createdBy: this.getLoggedInUser.id,
        id: this.vault.id,
        fileType: this.form.fileType,
        description: this.form.description,
        status: status,
        formData,
      })

      if (status === 'review') {
        this.$message({
          type: 'info',
          message: 'post submitted for approval',
        })
      }

      console.log('RESPONSE FROM BE ', response.file)
      setTimeout(() => {
        this.$emit('postUploaded')
      }, 300)
    },
  },
  computed: {
    ...mapGetters(['getLoggedInUser']),
  },
}
