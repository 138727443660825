// Copywrite Justin Roseman 2021
import { mapActions, mapGetters } from 'vuex'
import Masonry from 'masonry-layout'
import InfiniteLoading from 'vue-infinite-loading'
import { vaultAPI } from '@/apis/apis'
import SelectedVaultPost from './selected-vault-post'
import CreatePost from '../../components/create-post'
import VaultPost from './vault-post'
import { setTimeout } from 'core-js'

export default {
  name: 'Vault',
  data() {
    return {
      // centerDialogVisible: false,
      createPostModalOpen: false,
      complaintDialogOpen: false,
      complaint: { description: '' },
      direction: 'ltr',
      displayFilters: false,
      drawer: false,
      msgAdminTooltip: 'message admin',
      posts: [],
      postsShown: [],
      filterType: null,
      allFilterActive: true,
      imageFilterActive: false,
      videoFilterActive: false,
      audioFilterActive: false,
      documentFilterActive: false,
      selectedPost: null,
      openComment: false,
      uploadTooltip: 'upload media',
      shareTooltip: 'share',
      complaintTooltip: 'file complaint',
      vault: {},
      isFollowing: false,
      isAdmin: false,
      followers: [],
      followerIds: [],
      vaultAvatar: '',
      file: null,
      selectedAvatarFile: null,
      showShareOptions: false,
    }
  },
  components: {
    CreatePost,
    InfiniteLoading,
    SelectedVaultPost,
    VaultPost,
  },
  mounted() {
    this.positionPosts()
  },
  async created() {
    // const vaults = await this.fetchVaults()

    this.vault = await this.fetchVault(this.$route.params.id)
    const vaultPosts = (await this.fetchPosts(this.vault.id)).filter(post => post.status === 'approved')
    this.isAdmin = this.currentUser.id === this.vault.user_account_id

    this.vaultAvatar = this.vault.avatar ? `${this.vault.avatar}` : null
    this.posts = vaultPosts.length !== 0 ? vaultPosts : null
    this.postsShown = vaultPosts

    // if there is a query postId select the post
    if (this.$router.history.current.query.postId) {
      let id = this.$router.history.current.query.postId
      this.selectPost(this.postsShown.filter(post => post.id === id)[0])
    }

    vaultAPI
      .get(`/followers/${this.vault.id}`)
      .then(res => {
        let { users } = res.data
        users.forEach(user => {
          this.followerIds.push(user.user_id)
        })
        this.followerIds.forEach(id => {
          this.getUser(id).then(res => {
            this.followers.push(res)
          })
        })

        this.followers.sort((a, b) => {
          return a.firstname.localeCompare(b.firstname)
        })
        this.isFollowing = users.filter(user => user.user_id === this.currentUser.id).length > 0
      })
      .catch(error => console.log(error))
  },
  computed: {
    ...mapGetters({
      currentUser: 'getLoggedInUser',
    }),
    fullname() {
      return this.vault.firstname + ' ' + (this.vault.middlename || '') + ' ' + this.vault.lastname
    },
    birthday() {
      return new Date(this.vault.birthday).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
    },
    deathday() {
      return new Date(this.vault.deathday).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
    },
  },
  methods: {
    ...mapActions(['fetchVaults', 'fetchVault', 'fetchPosts', 'followVault', 'getUser', 'uploadProfileImage']),
    closeChatWidget() {
      CometChatWidget.openOrCloseChat(false)
    },
    handleClose() {
      this.drawer = false
    },
    fileComplaint(userId) {
      this.complaintDialogOpen = true
    },
    filterPosts(filterType) {
      if (filterType === 'all') {
        this.postsShown = this.posts.filter(post => post.status === 'approved')
        this.allFilterActive = true
        this.documentFilterActive = false
        this.audioFilterActive = false
        this.videoFilterActive = false
        this.imageFilterActive = false
      } else this.postsShown = this.posts.filter(post => post.content_type === filterType)

      switch (filterType) {
        case 'image':
          this.imageFilterActive = true
          this.videoFilterActive = false
          this.audioFilterActive = false
          this.documentFilterActive = false
          this.allFilterActive = false
          break
        case 'video':
          this.videoFilterActive = true
          this.imageFilterActive = false
          this.audioFilterActive = false
          this.documentFilterActive = false
          this.allFilterActive = false
          break
        case 'audio':
          this.audioFilterActive = true
          this.videoFilterActive = false
          this.imageFilterActive = false
          this.documentFilterActive = false
          this.allFilterActive = false
          break
        case 'document':
          this.documentFilterActive = true
          this.audioFilterActive = false
          this.videoFilterActive = false
          this.imageFilterActive = false
          this.allFilterActive = false
          break
      }

      this.positionPosts(true)
      window.scrollTo(0, 0)
    },
    msgAdmin() {
      CometChatWidget.openOrCloseChat(true)
      CometChatWidget.chatWithUser(this.vault.user_account_id)
      console.log('id looking for', this.vault.user_account_id)
    },
    messageUser(userId) {
      CometChatWidget.openOrCloseChat(true)
      CometChatWidget.chatWithUser(userId)
    },
    async refreshPosts() {
      const vaultPosts = (await this.fetchPosts(this.vault.id)).filter(post => post.status === 'approved')
      this.posts = vaultPosts.length !== 0 ? vaultPosts : null
      this.postsShown = vaultPosts
      this.handleClose()
      this.createPostModalOpen = false
      this.positionPosts()
    },
    async subscribeToVault() {
      const res = await this.followVault({ vaultId: this.vault.id, userId: this.currentUser.id })
      if (res.success) {
        this.isFollowing = true
        // add to followers
        this.getUser(this.currentUser.id).then(res => {
          this.followers.push(res)
        })
      }
    },
    unSubscribeToVault() {
      vaultAPI
        .post(`/unfollow/${this.vault.id}`, { userId: this.currentUser.id })
        .then(res => {
          if (res.data.success) {
            this.isFollowing = false
            // remove from followers
            this.followers = this.followers.filter(f => f.id !== this.currentUser.id)
          }
        })
        .catch(error => console.log(error))
    },
    positionPosts(fromFilter = false) {
      this.$nextTick(() => {
        setTimeout(() => {
          let elem = document.querySelector('.vault_posts')
          let msnry = new Masonry(elem, {
            itemSelector: '.vault-post',
            columnWidth: 280,
          })
          const postWidth = 296
          const vaultBoxWidth = elem.clientWidth
          const numOfColumns = Math.floor(vaultBoxWidth / postWidth)
          const difference = vaultBoxWidth - numOfColumns * 256
          const moveBy = difference / 3.25
          elem.style.left = moveBy + 'px'
          console.log('numOfColumns', difference)
          msnry.layout()

          // position filters
          if (!fromFilter) {
            // const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            // const filtersBox = document.querySelector('.vault_filters')
            // const filterHeight = filtersBox.offsetHeight
            // console.log('filters positioned ', windowHeight / 2 + filterHeight / 2)
            // filtersBox.style.top = windowHeight / 2 - filterHeight / 2 - 500 + 'px'
            this.displayFilters = true
          }
        }, 1000)
      })
    },
    addComment(post) {
      this.selectPost(post)
      this.openComment = true
    },
    sharePost(post) {
      this.selectPost(post)
    },
    selectPost(post) {
      this.selectedPost = post
      // this.$router.push({ name: `vault`, params: { id: this.vault.id }, query: { postId: post.id } })
    },
    closeSelectedPost() {
      this.selectedPost = null
      this.$router.push({ name: `vault`, params: { id: this.vault.id } })
    },
    onImageSelect() {
      console.log('image selected')
      this.file = this.$refs.fileInput.files[0]
      console.log('this is file info ', this.file)
      this.selectedAvatarFile = URL.createObjectURL(this.file)
    },
    async onImageSubmit() {
      console.log('entered onImageSubmit', this.file)
      if (!this.file) {
        this.$message({
          type: 'error',
          message: 'must select image before uploading',
        })
        return
      }
      const formData = new FormData()
      formData.append('profile-upload', this.file)
      const response = await this.uploadProfileImage({ id: this.vault.id, type: 'vault', formData: formData })
      console.log('RESPONSE FROM BE ', response.file.bucket + response.file.key)
      if (response.file) {
        this.vaultAvatar = `${response.file.location}`
        this.selectedAvatarFile = null
      }
    },
    clearProfileImgSelection() {
      this.selectedAvatarFile = false
    },
  },
}
