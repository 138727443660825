// Copywrite Justin Roseman 2021
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import { commentAPI } from '@/apis/apis'
import { likeAPI } from '../../../apis/apis'

export default {
  name: 'SelectedVaultPost',
  components: {
    FontAwesomeIcon,
  },
  props: {
    post: {
      type: Object,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addComment: false,
      comments: [],
      commentText: null,
      commentSectionOpen: false,
      liked: false,
      shareSectionOpen: false,
    }
  },
  created() {
    this.fetchComments()
    likeAPI.get(`/${this.post.id}`).then(res => {
      this.liked = res.data.likes.filter(like => like.user_id === this.user.id).length > 0
    })
  },
  deactivated() {
    this.centerDialogVisible = false
  },
  computed: {
    ...mapGetters({
      user: 'getLoggedInUser',
    }),
    sharingTitle() {
      return `View new content for ${this.post.postUserData.firstname}  ${this.post.postUserData.lastname} on LifeShare`
    },
    sharingUrl() {
      let route = this.$router.currentRoute
      // return 'www.google.com'
      console.log(`http://lifeshare-frontend.s3-website-us-east-1.amazonaws.com${route.path}?postId=${route.query.postId}`)
      return `http://lifeshare-frontend.s3-website-us-east-1.amazonaws.com${route.path}?postId=${route.query.postId}`
    },
  },
  methods: {
    likePost() {
      if (!this.liked) {
        likeAPI
          .post('/', { postId: this.post.id, userId: this.user.id })
          .then(res => {
            this.liked = true
          })
          .catch(err => console.log(err))
      } else {
        likeAPI
          .post('/unlike', { postId: this.post.id, userId: this.user.id })
          .then(res => {
            if (res.data.success) this.liked = false
          })
          .catch(err => console.log(err))
      }
    },
    closeCommentSection() {
      this.addComment = false
    },
    showCommentOptions() {
      if (!this.commentSectionOpen) {
        this.commentSectionOpen = true
        this.shareSectionOpen = false
      } else {
        this.commentSectionOpen = false
      }
    },
    showShareOptions() {
      if (!this.shareSectionOpen) {
        this.shareSectionOpen = true
        this.commentSectionOpen = false
      } else {
        this.shareSectionOpen = false
      }
    },
    fetchComments() {
      commentAPI.get(`/${this.post.id}`).then(res => {
        console.log('response ', res)
        this.comments = res.data.comments
      })
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
      var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      // if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [monthNames[month], day].join('-')
    },
    submitComment() {
      commentAPI
        .post(`/`, { postId: this.post.id, postedBy: this.user.id, body: this.commentText })
        .then(res => {
          this.commentSectionOpen = false
          this.$message({
            type: 'info',
            message: 'comment submitted succesfully',
          })
          this.addComment = false
        })
        .catch(error => console.log(error))
    },
  },
}
