// Copywrite Justin Roseman 2021
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { likeAPI } from '../../../apis/apis'

export default {
  name: 'VaultPost',
  components: {
    FontAwesomeIcon,
  },
  props: ['post'],
  data() {
    return {
      liked: false,
    }
  },
  created() {
    likeAPI.get(`/${this.post.id}`).then(res => {
      this.liked = res.data.likes.filter(like => like.user_id === this.getLoggedInUser.id).length > 0
    })
  },
  computed: {
    ...mapGetters(['getLoggedInUser']),
  },
  methods: {
    goToStore(contentType) {
      console.log('store opened ', contentType)
    },
    likePost() {
      if (!this.liked) {
        likeAPI
          .post('/', { postId: this.post.id, userId: this.getLoggedInUser.id })
          .then(res => (this.liked = true))
          .catch(err => console.log(err))
      } else {
        likeAPI
          .post('/unlike', { postId: this.post.id, userId: this.getLoggedInUser.id })
          .then(res => {
            if (res.data.success) this.liked = false
          })
          .catch(err => console.log(err))
      }
    },
  },
}
